// src/pages/preferences/GetPreferences.js

import React, { useState } from 'react';
import PreferencesForm from '../../components/preferences-form';
import { API_BASE_URL_V0, API_BASE_URL_V1 } from '../../utils/constants';
import { fetchData } from '../../utils/apiUtils';
import useUserProfile from '../../hooks/useUserProfile';

const GetPreferences = ({
  userProfile,
  userPreferences,
  setUserPreferences,
  setIsSubmitted,
  setShowResponseContainer,
  setResponseText,
  setResponse,
  setInitialLoad,
  setShowImagePlaceholder,
  setPromptInteracted,
  setErrorMessage,
  setIsTrendDisplay,
  setShowPreferences
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { updatePreferences, preferencesHaveChanged } = useUserProfile();

  const handleInitialPageLoad = async (preferences, userPrefsNotPresent) => {
    console.log('handleInitialPageLoad Preferences:', preferences);
    const current_hour = new Date().getHours();
    const queryData = {
      query_str: 'Initial Request',
      preperences: true,
      userPrefsNotPresent: userPrefsNotPresent,
      current_hour: current_hour,
      user_name: userProfile.user_name,
      user_profile_id: userProfile.id,
      conversational_style: preferences?.conversational_style,
      personal_fashion_style: preferences?.personal_fashion_style,
      favorite_colors: preferences?.favorite_colors,
      fashion_material_prefer: preferences?.fashion_material_prefer,
      body_type: preferences?.body_type,
      fashion_goals: preferences?.fashion_goals,
      age_group: preferences?.age_group
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(queryData)
    };

    try {
      const queryPrefResults = await fetchData(`${API_BASE_URL_V0}/product/search/queryresponse`, requestOptions);
      console.log('queryPrefResults -->', queryPrefResults);

      if (!queryPrefResults) {
        console.log('No results found');
        setResponseText('No results found');
      } else {
        const { greeting, recommendation } = queryPrefResults.results;
        const resultText = (greeting ? greeting + " " : "") + recommendation;
        console.log('resultText -->', resultText);
        setResponseText(resultText);
        setShowResponseContainer(true);

        let limit = 40;
        let url = `${API_BASE_URL_V0}/product/search/text`;
        const sessionId = localStorage.getItem('session_id');
        const client_ip = localStorage.getItem('client_ip');
        const location = localStorage.getItem('client_location');
        const data = {
          query_str: recommendation,
          preperences: true,
          user_name: userProfile.user_name,
          user_profile_id: userProfile.id,
          conversational_style: preferences?.conversational_style,
          personal_fashion_style: preferences?.personal_fashion_style,
          favorite_colors: preferences?.favorite_colors,
          fashion_material_prefer: preferences?.fashion_material_prefer,
          body_type: preferences?.body_type,
          fashion_goals: preferences?.fashion_goals,
          age_group: preferences?.age_group,
          session_id: sessionId,
          client_ip: client_ip,
          location: location,
          limit: limit
        };

        try {
          const textResponse = await fetchData(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          });

          if (textResponse.results && textResponse.results.length > 0) {
            setResponse(textResponse.results);
            setPromptInteracted(true);
            setShowImagePlaceholder(true);
          } else {
            console.error('Unexpected response structure:', textResponse);
          }
        } catch (error) {
          console.error('Error when calling the local API endpoint:', error);
        }
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setErrorMessage('Failed to fetch data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserPreferencesSubmit = async (preferences) => {
    console.log('Submitted Preferences:', preferences);
    console.log('Submitted userProfile:', userProfile);
    setIsLoading(true);
    setIsTrendDisplay(false);
    // Validate userProfile presence and structure
    if (!userProfile || typeof userProfile.id !== 'number') {
      console.error('Invalid or missing userProfile:', userProfile);
      setIsLoading(false);
      return; // Exit the function if userProfile is not valid
    }

    let userPrefsNotPresent = false;
    const user_profile_id = userProfile.id;
    console.log('Submitted user_profile_id:', user_profile_id);

    const userPreferencesUrl = `${API_BASE_URL_V1}/user/preferences/${user_profile_id}`;

    try {
      const userPrefResponse = await fetch(userPreferencesUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      });
    
      console.log('User preferences fetched 11:', userPrefResponse);
      if (userPrefResponse.ok && !(userPrefResponse.detail === "User preferences are not found")) {
       
        const existingPreferences = await userPrefResponse.json();
        if (preferencesHaveChanged(existingPreferences, preferences)) {
          console.log('Differences detected, updating preferences');
          await updatePreferences(user_profile_id, preferences, true);
          setUserPreferences(preferences);
          setIsSubmitted(true);
        } else {
          console.log('No differences in preferences, no update necessary.');
          setIsSubmitted(true);
        }
      } else if ((userPrefResponse.ok && userPrefResponse.detail === "User preferences are not found")
        || userPrefResponse.status === 404) {
        console.log('User preferences not present, creating new');
        userPrefsNotPresent = true;
        await updatePreferences(user_profile_id, preferences, false);
        setIsSubmitted(true);
        setUserPreferences(preferences);
      } else {
        throw new Error(`HTTP error! Status: ${userPrefResponse.status}`);
      }

      // Call new API to load the random products based on the user preferences
      const current_hour = new Date().getHours();
      const queryData = {
        current_hour: current_hour,
        userPrefsNotPresent: userPrefsNotPresent,
        user_name: userProfile.user_name,
        user_profile_id: user_profile_id,
        limit: 40
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify(queryData)
      };

      try {
        const url = `${API_BASE_URL_V1}/user/preferences/randomdata`;
        const userPrefResults = await fetchData(url, requestOptions);
        console.log('userPrefResults -->', userPrefResults);
        const { greeting, data } = userPrefResults.results;
        if (data && data.length > 0) {
          setResponseText(greeting);
          setShowResponseContainer(true);
          setResponse(data);
          setPromptInteracted(true);
          setShowImagePlaceholder(true);
          setIsLoading(false);
        } else {
          // This call should be on initial page load
          handleInitialPageLoad(preferences, userPrefsNotPresent);
        }
      } catch (error) {
        console.error('Error fetching user preferences random results:', error);
      }

      setInitialLoad(false);
    } catch (error) {
      console.error('Error fetching or updating user preferences:', error);
    }
  };

  const handleClosePreferences = () => {
    setShowPreferences(false);
  };

  return (
    <div className="app">
      <div className="shopbot-title">
        <span className="sibi-text">Shopper</span><span className="genai-text">GPT</span>
      </div>
      {!isLoading && (
        <PreferencesForm onSubmit={handleUserPreferencesSubmit} onCancel={handleClosePreferences} initialData={userPreferences} />
      )}
      {isLoading && <div className="loader"></div>}
    </div>
  );
};

export default GetPreferences;
