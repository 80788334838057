import { v4 as uuidv4 } from 'uuid';
import { fetchData } from './apiUtils';
import { IP_API_URL, LOCATION_API_URL } from './constants';

export const arrayBufferToBase64 = (arrayBuffer) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = () => {
            reject(new Error('Error converting array buffer to base64'));
        };
        reader.readAsDataURL(new Blob([arrayBuffer]));
    });
};

export const initializeSession = () => {
    let sessionId = localStorage.getItem('session_id');
    if (!sessionId) {
        sessionId = uuidv4();
        localStorage.setItem('session_id', sessionId);
    }
};

export const fetchIpAddress = async () => {
    try {
        let client_ip = localStorage.getItem('client_ip');
        if (!client_ip) {
            const ipData = await fetchData(IP_API_URL);
            localStorage.setItem('client_ip', ipData.ip);
            await fetchLocation(ipData.ip); // Fetch location after getting IP
        } else {
            // Optionally, fetch location if IP exists but location does not
            await fetchLocation(client_ip);
        }
    } catch (error) {
        console.error("Error fetching IP address:", error);
    }
};

export const fetchLocation = async (ip) => {
    try {
        let client_location = localStorage.getItem('client_location');
        if (!client_location && ip) { // Corrected the logical AND condition
            const locData = await fetchData(`${LOCATION_API_URL}/${ip}`);
            if (locData && !locData.error) { // Check if data is successfully retrieved and no error
                const locationInfo = {
                    country: locData.country,
                    regionName: locData.regionName,
                    city: locData.city,
                };
                localStorage.setItem('client_location', JSON.stringify(locationInfo)); // Store location info
            }
        }
    } catch (error) {
        console.error("Error fetching location:", error);
    }
};

// Define the sample search keys
const EXAMPLE_SEARCH_KEYS = [
    "I’m in search of a casual denim jacket that’s perfect for a cool evening out. It should be made of pure cotton. I’d prefer it to be slightly oversized but not too baggy.",
    "I’m looking for a pair of high-waisted skinny jeans. They should be stretchy and, ideally a blend of cotton and elastane. They should fit snugly.",
    "I need a chic silk blouse for office wear. It should be lightweight and in a neutral color. I’d prefer it to have a loose fit and long sleeves.",
    "I’m searching for a wool blend trench coat for the winter season in New. I’d prefer it to have a belt and a warm lining.",
    "I’m after a floral summer dress made of lightweight materials like cotton or linen. It should be brightly colored with a flowy skirt. I’d prefer it to be midi length and have short sleeves.",
    "I’m looking for a knitted sweater for cozy winter nights here in Colorado. It should be made of soft wool or cashmere. I’d prefer it to be oversized and have a high neck.",
    "I’m searching for a pair of athletic leggings for my gym sessions. They should be made of a breathable synthetic blend. I’d prefer them to be high-waisted and have a tight fit.",
    "I’m looking for a pair of ankle boots that are comfortable for walking. They should be made of genuine leather, preferably in a neutral color.",
    "I’m searching for a maxi skirt that’s perfect for a casual day out in the Fall here in San Francisco. It should be made of a lightweight material like cotton or linen, and in a solid color or a subtle print. I’d prefer it to have a high waist and a flowy silhouette.",
    "I’m after a black leather jacket that can add an edge to my style.",
    "I’m looking for a pair of ballet flats for everyday wear. They should be comfortable enough and durable, ideally Black please of leather. I’d prefer them in a versatile color like black or nude.",
    "I’m searching for a jumpsuit for a special occasion. It should be made of a dressy material like silk or satin, and in a dark color like black or navy. I’d prefer it to have a fitted waist and wide-leg pants.",
];

export const getRandomExampleKeys = (numKeys, existingKeys) => {
    let exampleKeys = EXAMPLE_SEARCH_KEYS.filter(key => !existingKeys.includes(key));
    let result = [];

    while (result.length < numKeys && exampleKeys.length > 0) {
        const randomIndex = Math.floor(Math.random() * exampleKeys.length);
        result.push(exampleKeys[randomIndex]);
        exampleKeys.splice(randomIndex, 1);
    }

    return result;
};
