import React from 'react';
import { Routes, Route } from 'react-router-dom';
import App from './App';
import GetPreferences from './pages/preferences';
import ResultsListing from './pages/results';
import { AuthProvider } from '@descope/react-sdk';

const RoutesComponent = () => (
  <React.StrictMode>
    <AuthProvider projectId='P2g3FmSi3iwdkAoeZmpVMWUM7vcq'>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/preferences" element={<GetPreferences />} />
        <Route path="/results" element={<ResultsListing />} />
      </Routes>
    </AuthProvider>
  </React.StrictMode>
);

export default RoutesComponent;