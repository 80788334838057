import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const getVideoConstraints = () => {
  if (isMobile) {
    return { facingMode: "environment" };
  }
  return { facingMode: "user" };
};

const WebcamCaptureComponent = ({ open, onClose, onCapture }) => {
  const webcamRef = useRef(null);
  const [videoConstraints, setVideoConstraints] = useState(getVideoConstraints());

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
    onClose();
  };

  const handleUserMediaError = (error) => {
    console.error('Webcam error:', error);
    if (error.name === 'OverconstrainedError') {
      // Fallback to user-facing camera if environment-facing camera is not available
      setVideoConstraints({ facingMode: "user" });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Capture Image</DialogTitle>     
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMediaError={handleUserMediaError}
      />
      <DialogActions>
        <Button onClick={handleCapture}>Capture</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebcamCaptureComponent;
