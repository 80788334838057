import React from 'react';

const ProductCard = ({ product, handleProductImageClick, handleProductIconClick }) => {
  return (
    <div className="image-placeholder">
      <div className="image-container">
        <img src={product.link} alt={product.productdisplayname} onClick={handleProductImageClick} />
        <button className="search-icon-button" onClick={handleProductIconClick}>
          <img src="search-icon.png" alt="Search Icon" />
        </button>
      </div>
      <p className="product-name">
        {product.productdisplayname.substring(0, 60)}{product.productdisplayname.length > 60 ? "..." : ""}
      </p>
      <p className="product-price">{product.price}</p>
    </div>
  );
};

export default ProductCard;
