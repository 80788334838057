import React, { useRef } from "react";
import Cropper from "react-cropper";
import "../assets/styles/cropper.css";

const BoundingBoxRo = ({ customImage }) => {
  const cropperRef = useRef(null);
  return (
    <div className="custom-image-placeholder">
      <Cropper
        src={customImage}
        style={{ height: 300, width: "auto" }}
        viewMode="3"
        dragMode="none"
        autoCropArea="1"
        guides={false}
        restore={false}
        modal={false}
        highlight={false}
        cropBoxMovable={false}
        cropBoxResizable={false}
        toggleDragModeOnDblclick={false}
        ref={cropperRef}
      />
    </div>
  );
};
export { BoundingBoxRo };