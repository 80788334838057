import React from 'react';
import { Descope } from '@descope/react-sdk';

function AuthenticationComponent({ handleDeScopeSuccess }) {
  return (
    <Descope
      flowId="sign-up-or-in"
      onSuccess={handleDeScopeSuccess}
      onError={(e) => console.log('Could not log in!')}
    />
  );
}

export default AuthenticationComponent;
