import React, { useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "../assets/styles/cropper.css";

const BoundingBox = ({ customImage, onCropData }) => {
  const cropperRef = useRef(null);

  useEffect(() => {
      const cropper = cropperRef.current;
      if (cropper) {
        // Set initial crop box data
        cropper.cropper.setCropBoxData({
          left: 10,
          top: 10,
          width: 220,
          height: 275
        });
      }
    }, [customImage]); // Run this effect when customImage changes
  
  
  const handleCrop = () => {
    const cropper = cropperRef.current;
    if (!cropper) return;

    const data = cropper.cropper.getData(true); // Get crop box data
    //console.log('Crop Data:', data);
    onCropData(data); // Pass data to parent
  };

  return (
    <div className="custom-image-placeholder">
      <Cropper
        src={customImage}
        style={{ height: 300, width: "auto" }}
        initialAspectRatio={16 / 9}
        guides={false}
        crop={handleCrop}
        ref={cropperRef}
      />
    </div>
  );
};

const handleImageData = (imageDataUrl, onCropData) => {  
  // Pass image data URL to BoundingBox component
  onCropData(imageDataUrl);
};

export { BoundingBox, handleImageData };