import axios from 'axios';
import { arrayBufferToBase64 } from './helper';

export const fetchImageData = async (productLink) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_V1}/proxy-image?url=${productLink}`, {
      responseType: 'arraybuffer'
    });

    const arrayBuffer = response.data;
    const base64ImageData = await arrayBufferToBase64(arrayBuffer);
    const contentType = response.headers['content-type'];
    const imageDataUrl = `data:${contentType};base64,${base64ImageData}`;
    return { imageDataUrl, arrayBuffer, contentType };
  } catch (error) {
    throw new Error('Failed to fetch image data');
  }
};