import React from 'react';

const ResultsListing = () => {
  return (
    <div>
      <h2>Result Page</h2>
      {/* Add your result content here */}
    </div>
  );
};

export default ResultsListing;