import React, { useState, useEffect } from 'react';

export default function PreferencesForm({ onSubmit, onCancel, initialData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    conversational_style: '',
    personal_fashion_style: '',
    favorite_colors: [],
    age_group: '',
    fashion_material_prefer: [],
    body_type: '',
    fashion_goals: ''
  });

  useEffect(() => {
    setIsLoading(false);
    if (initialData) {
      setFormData({
        conversational_style: initialData.conversational_style || '',
        personal_fashion_style: initialData.personal_fashion_style || '',
        favorite_colors: initialData.favorite_colors || [],
        age_group: initialData.age_group || '',
        fashion_material_prefer: initialData.fashion_material_prefer || [],
        body_type: initialData.body_type || '',
        fashion_goals: initialData.fashion_goals || ''
      });
    }
  }, [initialData]);

  const fieldNames = {
    conversational_style: "Conversational Style",
    personal_fashion_style: "Personal Fashion Style",
    favorite_colors: "Favorite Colors",
    age_group: "Age Group",
    fashion_material_prefer: "Fashion Material Preferences",
    body_type: "Body Type",
    fashion_goals: "Fashion Goals"
  };

  const checkPreferences = (preferences) => {
    const missingFields = Object.keys(preferences).filter(
      key => preferences[key] === '' || (Array.isArray(preferences[key]) && preferences[key].length === 0)
    );

    return missingFields.map(key => fieldNames[key] || key);
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'radio'
        ? value
        : (type === 'checkbox'
          ? (checked
            ? [...prevState[name], value]
            : prevState[name].filter(item => item !== value))
          : value) // use value instead of prevState[name]
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const missingFields = checkPreferences(formData);
    if (missingFields.length > 0) {
      setErrorMessage(`Please fill out all fields. Missing: ${missingFields.join(', ')}`);
    } else {
      setErrorMessage('');
      onSubmit(formData);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const nextStep = () => {
    if (step < labels.length - 1) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const labels = [
    { label: "What Is Your Conversational Style?", name: "conversational_style", options: ["Professional", "Fun", "Upbeat", "Young", "Urban", "Friendly"].sort(), type: 'radio' },
    { label: "Describe Your Personal Fashion Style:", name: "personal_fashion_style", options: ["Edgy", "Business", "Playful", "Sexy", "Androgynous", "Colorful", 'Simple'].sort(), type: 'radio' },
    { label: "What Age Group Do You Identify With?", name: "age_group", options: ["Gen Z - 12-27 Years Old", "Millenial - 28-43 Years Old", 'Gen X - 44-59 Years Old', 'Boomer - 60-78 Years Old'], type: 'radio' },
    { label: "What Are Your Favorite Colors?", name: "favorite_colors", options: ["Blacks", "Whites", 'Reds', 'Blues', 'Greens', 'Browns', 'Yellows', 'Oranges', 'Multicolors'].sort() },
    { label: "What Materials Do You Prefer?", name: "fashion_material_prefer", options: ['Blends', 'Leather', 'Denim', 'Cotton', 'Wool', 'Cashmere', 'Down', 'Suede', 'Silk', 'Sustainable', 'Synthetics'].sort() },
    { label: "What Is Your Body Type?", name: "body_type", options: ['Average', 'Fit', 'Slim', 'Full Figured', 'Curvy', 'Short', 'Tall'].sort(), type: 'radio' },
    { label: "What Are Your Fashion Goals?", name: "fashion_goals", options: ['To create a colorful, playful wardrobe', 'To create a striking, sexy, bold statement', 'To build a minimalist, versatile wardrobe', 'To incorporate more sustainable pieces', 'To experiment with bold patterns & textures', 'To cultivate a signature style'].sort(), type: 'radio' }
  ];

  if (isLoading) {
    return <div>Loading preferences...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="preferences-form">
      <div className="header-text">Help us personalize our recommendations just for you:</div>
      {labels.map((labelInfo, index) => (
        <div key={index} style={{ display: step === index ? 'block' : 'none' }}>
          <label className="form-label">{labelInfo.label}</label>
          {labelInfo.options.map(option => {
            const isChecked = labelInfo.type === 'radio'
              ? formData[labelInfo.name] === option
              : formData[labelInfo.name]?.includes(option) || false;
            return (
              <div key={option}>
                <input
                  type={labelInfo.type || 'checkbox'}
                  name={labelInfo.name}
                  value={option}
                  checked={isChecked}
                  onChange={handleInputChange}
                />
                {option}
              </div>
            );
          })}
        </div>
      ))}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="form-navigation">
        {step > 0 && (
          <button type="button" onClick={prevStep} className="prev-button">Previous</button>
        )}
        {step < labels.length - 1 ? (
          <button type="button" onClick={nextStep} className="next-button">Next</button>
        ) : null}

        {step === labels.length - 1 && (
          <>
            <button type="submit" className="submit-button">
              Save
            </button>
          </>
        )}

        <button
          type="button"
          onClick={handleCancel}
          className="cancel-button"
        >
          Cancel
        </button>
      </div>
    </form>
  );
}
