import React from 'react';
import shopperGPTArrows from "../../assets/images/shopperGPT-with-arrows.png";

function IntroductionComponent({ isLoading, handleDismiss }) {
  if (isLoading) {
    return <div className="loader"></div>;
  }

  return (
    <div className="how-to">
      <img src={shopperGPTArrows} className="how-to-image" alt='how to use' />
      <button onClick={handleDismiss} className="dismiss-button">{"Next"}</button>
    </div>
  );
}

export default IntroductionComponent;
